<template>
	<div>
		<ContentHeader title="Layanan" url="/layanan" subTitle="Tambah Layanan" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
	              <div class="card-header">
	                <h3 class="card-title"><div style="#007bff">Tambah Layanan</div></h3>

	                <div class="card-tools">
	                </div>
	              </div>
	              <div class="card-body row">
	              	<div class="col-lg-2"></div>
	              	<div class="col-lg-8">
	              		<form @submit.prevent="prosesSubmit">
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="nama">Nama <span class="text-danger">*</span></label>
			                    <input type="text" class="form-control" id="nama" name="nama" v-model="formState.nama" autocomplete="off" required="">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="status">Status</label>
			                    <br>
			                    <el-switch
								    v-model="formState.status"
								    class="mb-2"
								    active-text="Aktif"
								    inactive-text="Tidak Aktif"
								>
								</el-switch>
			                  </div>
			                </div>
			                <div class="px-lg-2">
			                	<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
									<div>Submit</div>	
									<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
									  <span class="sr-only">Loading...</span>
									</div>
								</button>
			                </div>
		                </form>
	              	</div>
	              	<div class="col-lg-2"></div>
	              </div>
	              <!-- /.card-body -->
	              <div class="card-footer" style="display: block;">
	                
	              </div>
	              <!-- /.card-footer-->
            	</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'

import { ref, onMounted } from 'vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import router from '@/router'
import { useRoute } from 'vue-router'


const route = useRoute()
const loading = ref(false)
const formState = ref({
	'nama' : '',
	'status' : false
})

const getData = async () => {
	let { data } = await axios.get(`api/layanan/getWhereId/${route.params.id}`)
	if (data != 'kosong') {
		formState.value = data
		formState.value.status = Boolean(formState.value.status)	
	}
}


const prosesSubmit = () => {
	loading.value = true
	axios.put(`api/layanan/update/${route.params.id}`, formState.value)
	.then(() => {
		Swal.fire({
		  title: 'Berhasil!',
		  text: 'Berhasil mengubah Data',
		  icon: 'success',
		  showConfirmButton: false,
		  timer: 1500
		})
		loading.value = false
		router.push('/layanan')
	})
	.catch(() => {
		Swal.fire({
		  title: 'Gagal!',
		  text: 'Gagal mengubah data',
		  icon: 'error',
		  confirmButtonText: 'Ok'
		})	
		loading.value = false
	})
}

onMounted(() => {
	getData()
})
</script>